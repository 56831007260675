/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { downloadAddon, ip } from "../../../api";
import '../AddonDetailPage.css'


const VersionSelectModal = ({ addon_instances, onClose, namespace, t }) => {
    const [lastDownloadTime, setLastDownloadTime] = useState(0);
    const handleDownload = async (event) => {
        event.preventDefault();

        try {
            const currentTime = Date.now();
            if (currentTime - lastDownloadTime < 3000) {
                alert(`${((3000 - currentTime + lastDownloadTime) / 1000).toFixed(1)} 초 후 다시 시도해보세요.`);
                return;
            }
            if (!document.getElementById('download').term_agree_button.checked) {
                alert(t('ui_term_agree'));
                return;
            }
            setLastDownloadTime(currentTime);

            const response = downloadAddon(namespace, event.target.id)   // 다운로드

            const contentDisposition = response.headers["content-disposition"];
            let filename = new Date().toDateString() + "[no-file-name].zip";
            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename\*?=(?:UTF-8'')?(.+)/);
                if (filenameMatch) {
                    filename = decodeURIComponent(filenameMatch[1].replace(/(^"|"$)/g, ""));
                }
            }
            // 파일 다운로드
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); // 파일 이름 지정
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            const download_count = document.getElementById('download_count');
            download_count.innerHTML = parseInt(download_count.innerHTML) + 1;
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <div id="version_select_modal">
            <div>
                <div>
                    <h3>{t('AddonDetailPage-Modal-title')}</h3>
                    <table>
                        <tr>
                            <td>{t('AddonDetailPage-Modal-table-version')}</td>
                            <td>{t('AddonDetailPage-Modal-table-date')}</td>
                            <td>{t('AddonDetailPage-Modal-table-download')}</td>
                        </tr>
                        {addon_instances.map(i => (
                            <tr>
                                <td>{i.version}</td>
                                <td>{new Date(i.date).toLocaleDateString()}</td>
                                <td>
                                    <button class="version_download" onClick={handleDownload}>
                                        <span class="material-symbols-rounded" id={i.version}>
                                            download
                                        </span>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
                <button id="moal_close" onClick={onClose}>&times;</button>
            </div>
        </div>
    )
}
export default VersionSelectModal;