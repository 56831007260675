import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import NoServerResponsePage from '../error/no server response'
import NotFoundPage from '../error/404 not found';
import PageTitle from './components/PageTitle';
import '../common/styles/main.css'
import './AddonDetailPage.css';
import { fetchAddons, isExisting } from '../../api';
import { useTranslation, Trans } from 'react-i18next';
import AddonImages from './components/AddonImages';
import AddonInfo from './components/AddonInfo';
import DownloadButton from './components/DownloadButton';
import Loading from '../common/components/Loading';
import i18next from 'i18next';


const AddonDetailPage = () => {
    const { t } = useTranslation()
    const { namespace } = useParams();
    const [addon, setAddon] = useState(null);
    const [download_count, setDc] = useState('');
    const [external_addon_status, setEAS] = useState('');

    useEffect(() => {
        const checkExistenceAndFetch = async () => {
            console.log(namespace)
            const exist = await isExisting(namespace); // await 사용
            if (exist === true) {
                const res = await fetchAddons(namespace, true, false);
                let formatted;
                let downloadCount = res.download_count;
                if (downloadCount < 10) formatted = 'AddonDetailPage-curt_info-download-less_than_10'
                else if (downloadCount < 50) formatted = '10+';
                else if (downloadCount < 100) formatted = '50+';
                else if (downloadCount < 500) formatted = '100+';
                else if (downloadCount < 1000) formatted = '500+';
                else formatted = (downloadCount / 1000).toFixed(1) + 'k';

                if (res.versions.at(-1).need_external_addon === 0) {
                    setEAS('X')
                }
                else if (res.versions.at(-1).need_external_addon === 1) {
                    setEAS('▲')
                }
                else {
                    setEAS('O')
                }
                setDc(formatted);
                setAddon(res);
            }
            else if (exist === false) {
                setAddon('No-Such-Addon')
            }
            else if (exist === 'offline') {
                setAddon('No-Server-Response')
            }
        };
        checkExistenceAndFetch();
    }, [namespace]);

    const getSeason = () => {
        const month = new Date().getMonth();
        if (month >= 2 && month <= 4) {
            return "spring"; // 3월 ~ 5월
        } else if (month >= 5 && month <= 7) {
            return "summer"; // 6월 ~ 8월
        } else if (month >= 8 && month <= 10) {
            return "autumn"; // 9월 ~ 11월
        } else {
            return "winter"; // 12월 ~ 2월
        }
    };

    if (!addon) {
        return (
            <Loading />
        )
    }
    else if (addon === 'No-Such-Addon') {
        return (
            <NotFoundPage reason={'No Such Addon'} />
        )
    }
    else if (addon === 'No-Server-Response') {
        return (
            <NoServerResponsePage />
        )
    }
    else {
        const selectedLanguage = i18next.language;  // i18n에서 선택된 언어
        const supportedLanguages = addon.langs;

        // 표시할 언어 및 나머지 개수를 결정
        const primaryLanguage = supportedLanguages.includes(selectedLanguage)
            ? selectedLanguage.toUpperCase()
            : supportedLanguages[0].toUpperCase(); // 선택된 언어가 지원되지 않으면 첫 번째 언어 표시

        const additionalLanguageCount = supportedLanguages.length - 1; // +X에 표시할 추가 언어 개수
        return (
            <main className="addon-detail-page" id={getSeason()}>
                <div id='head'>
                    <img id='preview_image' src={addon.preview_image} alt='' />
                    <section>
                        <div id='go_to_back'>
                            <Link to='/addons' title='Addons'>
                                <span translate='no' className="material-symbols-outlined"> arrow_back_ios </span>{t('AddonDetailPage-return_to_back')}
                            </Link>
                        </div>
                        <PageTitle addon={addon} t={t} />
                        <table>
                            <tr>
                                <td title={t('AddonDetailPage-curt_info-download')}>{t('AddonDetailPage-curt_info-download')}</td>
                                <td title={t('AddonDetailPage-curt_info-third_necessity')}>{t('AddonDetailPage-curt_info-third_necessity')}</td>
                                <td title={t('AddonDetailPage-curt_info-exp_necessity')}>{t('AddonDetailPage-curt_info-exp_necessity')}</td>
                                <td title={t('AddonDetailPage-curt_info-developer')}>{t('AddonDetailPage-curt_info-developer')}</td>
                                <td title={t('AddonDetailPage-curt_info-lang_support')}>{t('AddonDetailPage-curt_info-lang_support')}</td>
                            </tr>
                            <tr>
                                <td>{t(download_count)}</td>
                                <td>{external_addon_status}</td>
                                <td>{addon.need_experiment ? 'O' : 'X'}</td>
                                <td>{addon.author}</td>
                                <td><a id='lang_info' href='#addon-infos'>{primaryLanguage}{additionalLanguageCount > 0 && ` +${additionalLanguageCount}`}</a></td>
                            </tr>
                        </table>
                    </section>
                </div>
                <div id='body'>
                    <section>
                        <h2>{t('AddonDetailPage-AddonInfo-addon_screenshots')}</h2>
                        <AddonImages images={addon.screenshots} />

                        {/* 애드온 설명 */}
                        <h2>{t('AddonDetailPage-AddonInfo-addon_desc')}</h2>
                        <p id='description'>
                            {addon.translations[i18next.language.toLowerCase()].description.split('\\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}

                        </p>

                        {/* Changelog */}
                        <h2>{t('AddonDetailPage-AddonInfo-addon_changelog')}</h2>
                        <p id='description'>
                            {addon.translations[i18next.language.toLowerCase()].changelog.split('\\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}

                        </p>

                        {/* 애드온 정보 */}
                        <h2>{t('AddonDetailPage-AddonInfo-addon_infos')}</h2>
                        <AddonInfo addon={addon} t={t} />

                        {/* 약관 */}
                        <h2 id='term_of_use'>{t('AddonDetailPage-AddonInfo-term')}</h2>

                        <ol>
                            <li>
                                <strong>상업적 사용 제한</strong><br />
                                본 애드온은 개인적인 용도로만 사용할 수 있으며, 상업적 이용은 금지됩니다.<br />
                                <em>This addon is for personal use only and may not be used for commercial purposes.</em>
                            </li>
                            <li>
                                <strong>저작권 보호</strong><br />
                                애드온의 모든 저작권 및 지적 재산권은 제작자에게 있으며, 사용자는 이를 양도받지 않습니다.<br />
                                <em>All copyrights and intellectual property rights of the addon belong to the creator, and no rights are transferred to the user.</em>
                            </li>
                            <li>
                                <strong>수정 및 재배포 금지</strong><br />
                                애드온을 수정, 복제, 재배포하거나 내부 파일의 내용을 추출하는 행위를 하면 안 됩니다..<br />
                                <em>Users may not modify, reproduce, redistribute, or extract any contents from the addon files.</em>
                            </li>
                            <li>
                                <strong>면책 조항</strong><br />
                                애드온 사용으로 발생하는 문제에 대한 책임은 사용자에게 있으며, 제작자는 이에 대한 법적 책임을 지지 않습니다.<br />
                                <em>Users are solely responsible for any issues arising from the use of the addon, and the creator assumes no legal liability.</em>
                            </li>
                            <li>
                                <strong>개인정보 보호</strong><br />
                                본 애드온은 추가적인 개인정보를 수집하지 않습니다.<br />
                                <em>No additional personal information is collected.</em>
                            </li>
                            <li>
                                <strong>배포 및 변경 권한</strong><br />
                                제작자는 사전 공지 없이 애드온의 배포를 중단하거나 내용을 변경할 수 있습니다.<br />
                                <em>The creator reserves the right to discontinue or modify the distribution of the addon without prior notice.</em>
                            </li>
                            <li>
                                <strong>약관 변경</strong><br />
                                제작자는 필요할 경우, 별도의 공지 없이 본 약관을 수정할 수 있습니다.<br />
                                <em>The creator may update these terms at any time without prior notice.</em>
                            </li>
                            <li>
                                <strong>추가적인 규정 안내</strong><br />
                                제작자에 따라 애드온 별로 추가 약관이 있을 수 있으므로 반드시 확인하시기 바랍니다.<br />
                                <em>Depending on the producer, there may be additional terms and conditions for each addon, so be sure to check.</em></li>

                        </ol>
                        <strong>제작자 추가 라이센스</strong>
                        <div>
                            {addon.license === '[N/A]' ? '-' : addon.license}
                        </div>

                        <DownloadButton addon={addon} t={t} />
                    </section>
                </div>
            </main>
        );
    }
};

export default AddonDetailPage;