import React from "react";
import '../../common/styles/main.css';
import failToloadImage from '../../../assets/image/MOWS_image_load_fail.svg';
import '../AddonDetailPage.css'
import { ip } from "../../../api";
import i18next from "i18next";

const PageTitle = ({ addon, t }) => {
    const imgURL = addon.icon ? `${ip}${addon.icon}` : failToloadImage;
    return (
        <header>
            <img src={imgURL} alt="null" />
            <div>
                <div id="main">
                    <h1>{addon.translations[i18next.language.toLowerCase()].name}</h1>
                    <h2>{addon.translations[i18next.language.toLowerCase()].description.split("\\n")[0]}</h2>
                </div>
                <div id="sub">
                    <a href="#download_button">{t('download')}</a>
                    <a href={`https://docs.mows.kr/${addon.namespace}`}>{t('manual')}</a>
                </div>
            </div>
        </header>
    )
}

export default PageTitle;