import React from 'react';
import { Link } from 'react-router-dom';
import '../common/styles/main.css'
// import './NotFoundPage.css';

const NoServerResponsePage = () => {
    return (
        <div className="no-server-response-page">
            <h1><span translate='no' className="material-symbols-outlined"> database_off </span>서버 응답 없음. No Server Response.</h1>
            <h2>나중에 다시 시도하세요. Try again later.</h2>
            <Link to="/">Go to Home</Link>
        </div>
    );
};

export default NoServerResponsePage;