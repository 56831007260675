import axios from 'axios';


export const ip = 'https://api.mows.kr'

const setToCache = (url, data) => {
    const cacheData = {
        data,
        timestamp: Date.now(),
    };
    localStorage.setItem(url, JSON.stringify(cacheData));
};

// 캐시된 데이터를 확인하고, 없으면 요청을 보내는 함수
const cachedRequest = async (url, pass = false, check = false) => {     // url: 요청url, pass: 캐시검사, check: 서버 동작 체크
    if (check) {
        if ((await axios.get(`${ip}/api/ok`)).data['status'] !== "OK")
            return 'offline'
    }

    if (pass) return (await axios.get(`${ip}/api${url}`)).data;
    let cachedData;
    const cached = localStorage.getItem(url);
    console.warn('cached = ' + cached)
    if (cached != null) {
        const { data, timestamp } = JSON.parse(cached);
        const CACHE_EXPIRY_TIME = 1 * 60 * 1000;
        if (Date.now() - timestamp < CACHE_EXPIRY_TIME) {
            cachedData = data;
            console.log('캐시에서 데이터 반환:', cachedData);
            return Promise.resolve(cachedData);
        }
        else {
            const response = await axios.get(`${ip}/api${url}`);
            setToCache(url, response.data);  // 서버 응답 데이터를 캐시에 저장
            return response.data;
        }
    }
    else {
        const response = await axios.get(`${ip}/api${url}`);
        setToCache(url, response.data);  // 서버 응답 데이터를 캐시에 저장
        return response.data;
    }
};


// API 요청 함수들
export const fetchAddons = async (namespace = '', pass = false, check = true) => {
    try {
        const data = await cachedRequest(`/addons/${namespace}`, pass, check);
        console.warn('recieved data:' + data)
        return data;
    }
    catch {
        return 'offline'
    }
};

export const isExisting = async (namespace) => {
    try {
        const data = await cachedRequest(`/exist/${namespace}`, true, true);
        if (data === 'offline') return 'offline';
        return data['exists'];
    }
    catch {
        return 'offline'
    }
};

export const fetchAddonImages = async (code_name) => {
    console.log('Trying to fetch image');
    try {
        const data = await cachedRequest(`/addons/images/${code_name}`, false);
        return data;
    } catch (error) {
        return -1;
    }
};

export const downloadAddon = async (namespace, version) => {
    const response = await axios.post(`${ip}/api/addons/download/`, { namespace: namespace, version: version }, {
        responseType: 'blob',
        withCredentials: true,  // CORS 인증 헤더 추가
    });
    return response
}