/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchAddons } from '../../../api';
import { useTranslation } from "react-i18next"
import '../HomePage.css'
import '../../common/styles/main.css'
import NO_IMG from '../../../assets/image/MOWS_no_image.svg';
import { ip } from "../../../api";
import i18next from "i18next";

const MajorAddon = () => {
    const { t } = useTranslation();
    const [addons, setAddons] = useState([]);

    useEffect(() => {
        fetchAddons().then(data => {
            console.log(data)
            if (data !== -1) {
                let sortedAddons = [...data.addons];
                sortedAddons.sort((a, b) => b.download_count - a.download_count);
                setAddons(sortedAddons.slice(0, 5));
            }
        });
    }, []);

    return (
        <section className="sections" id='addons'>
            <h2>{t('HomePage_MainContent_majoraddons')}</h2>
            <div>
                <Link to='addons' className="addons_button">
                    <div className="addon-icon">
                        <span translate="no" className="material-symbols-outlined">
                            explore
                        </span>
                    </div>
                    <h3>{t('HomePage_MainContent_majoraddons-more')}</h3>
                </Link>
                {addons.map(i => (
                    <Link to={`addons/${i.namespace}`} className="addons_button">
                        <img className='addon-icon' src={i.icon ? `${ip}${i.icon}` : NO_IMG} alt={i.code_name} />
                        <h3>{i.translations[i18next.language.toLowerCase()] === undefined ? '--' : i.translations[i18next.language.toLowerCase()].name}</h3>
                        <div className='addons_info'>
                            <h4><span translate='no' className="material-symbols-outlined">account_circle</span>{i.author}</h4>
                            <h4><span translate='no' className="material-symbols-outlined">download</span>{i.download_count}</h4>
                            <h4><span translate='no' className="material-symbols-outlined">update</span>{new Date(i.latest_version_date).toLocaleDateString()}</h4>
                        </div>
                        <div className="addons_tags">
                            {i.tags.map(tag => (
                                <h6>#{tag}</h6>
                            ))}
                        </div>
                    </Link>
                ))}
            </div>
        </section >
    )
};

export default MajorAddon;